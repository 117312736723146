<template>
  <div>
    <p class="m-0 text-white cursor-pointer button-hover-ia d-flex mr-md-1" @click="$router.push({name: 'brandme_AI', params: {section: 'community'}})">
      <b-img :src="require('@/assets/images/svg/brandme_ia_white.svg')" class="image-icon-brandme-ia"></b-img>
      <span class="d-none d-md-block">BrandMe IA</span>
    </p>
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  name: 'ButtonModal',
  components: {
    BImg,
  },
}
</script>
<style scoped>
.image-icon-brandme-ia {
  width: 24px;
  height: 24px;
  margin-right: 0.5em;
}
</style>
<style>
  @media(max-width: 700px) {
    .button-hover-ia {
      color: white;
      padding: 11px;
      width: 3.125rem;
      height: 3.125rem;
      cursor: pointer;
      margin-right: 2.5em;
    }
    .button-hover-ia:hover {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10em;
      cursor: pointer;
    }
  }
</style>
